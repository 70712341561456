import React, { useEffect } from "react";
import * as echarts from "echarts";
import { formatCurrency2, formatCurrency } from "../../utils/Utils";
import { Empty } from "antd";
const InvoiceToPaymentSummaryWidget: React.FC<any> = ({ data }) => {
  // const data = [
  //     { name: 'Overdue', value: 401404 },             // 401,404
  //     { name: 'Paid (Partially/Fully)', value: 328902 }, // 328,902
  //     { name: 'Outstanding', value: 235935 },         // 235,935
  //     { name: 'Invoiced', value: 404969 },            // 404,969
  //     { name: 'Dispatched', value: 480418 }           // 480,418
  // ];

  useEffect(() => {
    const chartDom = document.getElementById(
      "invoice-summary-widget"
    ) as HTMLElement;
    const myChart = echarts.init(chartDom);

    const option = {
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          return `${params.name}: ${formatCurrency(params.value, "INR")} (${
            params.percent
          }%)`; // Use formatCurrency for values
        },
      },
      series: [
        {
          name: "Invoice to Payment Summary",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: true,
            formatter: (params: any) => {
              return `{b|${params.name}}\n{c|${formatCurrency(
                params.value,
                "INR"
              )}}`;
            },
            rich: {
              b: {
                fontSize: 14,
                color: "#333",
                lineHeight: 24,
              },
              c: {
                fontSize: 12,
                color: "#999",
                lineHeight: 20,
              },
            },
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 10,
            smooth: true,
          },
          itemStyle: {
            borderColor: "#E0E4FF", // Border color
            borderWidth: 1, // Border width
            shadowBlur: 10, // Shadow blur
            shadowOffsetX: 0, // Shadow X offset
            shadowOffsetY: 5, // Shadow Y offset
            shadowColor: "rgba(255, 255, 255, 0.2)", // Shadow color
            borderRadius: 5,
          },
          data: data.map((item: any) => ({
            value: item.value,
            name: item.name,
          })),
        },
      ],
      color: ["#173F5F", "#20639B", "#3CAEA3", "#F6D55C", "#ED553B"],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [data]);

  return (
    <>
      <div className="card-title-group mb-1">
        <div className="card-title">
          <h6 className="title">Invoice to Payment Summary</h6>
          <p>
            Overview of Invoicing, Payments, Dispatched, and Overdue Statuses.
          </p>
        </div>
      </div>
      {data ? (
        <div
          id="invoice-summary-widget"
          style={{ width: "100%", height: "300px" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "300px",
          }}
        >
          <Empty description="No data available to generate or display the graph." />
        </div>
      )}
    </>
  );
};

export default InvoiceToPaymentSummaryWidget;
