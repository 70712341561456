import React, { useState, useEffect, FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { FormProvider, useForm } from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Drawer,
  Upload,
  Select,
  Popover,
  DatePicker,
  Empty,
} from "antd";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import purchaseOrderApis from "../../api/purchase-order/PurchaseOrder";
import dayjs from "dayjs";
import axios from "axios";
import InvoiceAside from "./InvoiceAside";
import statusMapper from "../purchase-orders/StatusMapper";
import QuotationApprovalDrawer from "./QuotationApprovalDrawer";
import { nanoid } from "nanoid";
import Misc from "../masters/Misc";
import { Option } from "antd/es/mentions";
import invoiceApis from "../../api/Invoice/invoice";
import Swal from "sweetalert2";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "../purchase-orders/SignatureModal";
import usersApis from "../../api/master/users";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchSequenceAccess } from "../../store/slices/misc/sequenceAccess";
import { formatCurrency, formatDecimal } from "../../utils/Utils";
import PdfGenerator from "./SellerToBuyerInvoicePDF";
import { RootState } from "../../store/store";
import FormTextArea from "../form-components/TextAreaComponent";
const { Panel } = AntCollapse;

const { FormatCurrency, beforeUpload } = Misc;
type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}

const columns = [
  // {
  //   ...Table.EXPAND_COLUMN,
  //   fixed: "left",
  // },
  {
    title: "#",
    key: "#",
    width: 50,
    // fixed: "left",
    render: (text: any, record: any, index: number) => index + 1,
  },
  {
    title: "Material Name",
    dataIndex: "materialName",
    key: "materialName",
    width: 200,
    fixed: "left",
  },
  {
    title: "Ordered Qty",
    dataIndex: "quantity",
    key: "quantity",
    width: 150,
    render: (text: any, record: any) => `${Number(text)} ${record.uom}`,
  },
  {
    title: "Available Qty to Invoice",
    dataIndex: "availableQuantity",
    width: 200,
    render: (_: any, record: any) => (
      <div>
        {Number(record?.quantity) -
          Number(record?.quantityInvoiced) +
          " " +
          record.uom}
      </div>
    ),
  },
  {
    title: "Invoice Qty",
    dataIndex: `invoiceQuantity`,
    key: "invoiceQuantity",
    width: 120,
    editable: true,
    render: (text: any, record: any) => <div></div>,
  },
  {
    title: "Unit Price",
    dataIndex: "unitPrice",
    key: "unitPrice",
    width: 150,
    editable: true,
  },
  {
    title: "Need By Date",
    dataIndex: "needByDate",
    key: "needByDate",
    width: 150,
    render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
  },
  {
    title: "Basic Amount",
    dataIndex: "basicAmount",
    key: "basicAmount",
    width: 200,
  },
  {
  title: "SGST",
  dataIndex: "sgst",
  key: "sgst",
  width: 200,
  render: (text: any, record: any) => (
    <div style={{ textAlign: "right" }}>
      <span>
       {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"} 
      </span>
    </div>
  ),
},
{
  title: "CGST",
  dataIndex: "cgst",
  key: "cgst",
  width: 200,
  render: (text:any, record:any) => (
    <div style={{ textAlign: "right" }}>
      <span>
       {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text,"INR"):"N/A"} 
      </span>
    </div>
  ),
},
{
  title: "IGST",
  dataIndex: "igst",
  key: "igst",
  width: 200,
  render: (text:any, record:any) => (
    <div style={{ textAlign: "right" }}>
      <span>
        {(!Number(record?.sgst) &&!Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"}
      </span>
    </div>
  ),
},
  // {
  //   title: "SGST",
  //   dataIndex: "sgst",
  //   key: "sgst",
  //   width: 200,
  // },
  // {
  //   title: "CGST",
  //   dataIndex: "cgst",
  //   key: "cgst",
  //   width: 200,
  // },
  // {
  //   title: "IGST",
  //   dataIndex: "igst",
  //   key: "igst",
  //   width: 200,
  // },
  {
    title: "Line Total",
    dataIndex: "grossTotal",
    key: "grossTotal",
    width: 250,
  },
  {
    title: "Invoices",
    dataIndex: "operation",
    width: 100,
    fixed: "right",
  },
];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex + `_${record.uuid}`}
          style={{ margin: 0 }}
          rules={[
            {
              type: "number",
              min: 0.01,
              message: `${title} must > 0`,
            },
          ]}
          initialValue={Number(record[dataIndex])}
        >
          <InputNumber
            defaultValue={Number(record[dataIndex])}
            className="w-100"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
            onKeyPress={(event) => {
              const regex = /^[0-9\b\.]+$/;
              if (!regex.test(event.key)) {
                event.preventDefault();
              }
            }}
            step={0.01}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const InvoiceDetails: FC<any> = ({ orderNumber, previousPath,extra }) => {
  const { data: currentUser }: any = useSelector(
    (state: RootState) => state.currentUser
  );
  const [disableSubmit, setDisableSubmit] = useState(false);
//   const { orderNumber }: any = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [invoiceableLines, setInvoiceableLines] = useState<any>([]);
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [poStatus, setPoStatus] = useState<any>("");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [attachments, setAttachments] = useState<any>([]);
  const [signature, setSignature] = useState<any>([]);
  const [invoiceSignature, setInvoiceSignature] = useState<any>({});
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [approvalRequired, setApprovalRequired] = useState("false");
  const [invoiceDate, setInvoiceDate] = useState<any>();
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");

  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editingKeys, setEditingKeys] = useState<string[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editAdditionalCharges, setEditAdditionalCharges] = useState(true);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
    useState<any>(null);
  const [selectedNeedByDate, setSelectedNeedByDate] = useState<any>(null);
  const [needByDateList, setNeedByDateList] = useState<any>([]);
  const isEditing = (record: any) => editingKeys.includes(record.uuid);
  const [showCropModel, setShowCropModel] = useState<boolean>(false);
  const [showSignatureModel, setShowSignatureModel] = useState<boolean>(false);
  const [prefferedStates, setPrefferedStates] = useState<any>([]);
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );

  const dispatch: any = useDispatch();

  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  const mergedColumns = columns.map((col: any) => {
    if (col.dataIndex == "operation") {
      return {
        title: "Invoices",
        dataIndex: "operation",
        width: 100,
        fixed: "right",
        render: (text: any, record: any) => {
          return ["PartiallyInvoiced", "FullyInvoiced"].includes(
            record?.invoiceStatus
          ) ? (
            <Button
              size="sm"
              color=""
              onClick={() => {
                navigate(
                  `/view-material-invoices/${orderNumber}/${record.lineNo}`
                );
              }}
            >
              <Icon name="focus" />
            </Button>
          ) : (
            <></>
          );
        },
      };
    } else if (
      ["basicAmount",
        //  "sgst", "cgst", "igst",
          "grossTotal"].includes(
        col.dataIndex
      )
    ) {
      return {
        ...col,
        render: (text: any, record: any) => (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(
                text,
                currency?.currency_code
              )}
            </span>
          </div>
        ),
      };
    } else if (col.dataIndex == "unitPrice") {
      col = {
        ...col,
        render: (text: any, record: any) => (
          <div style={{ textAlign: "right" }}>
            <span>
              {formatCurrency(
                text,
                currency?.currency_code
              )}
            </span>
          </div>
        ),
      };
    } else if (col.dataIndex == "needByDate") {
      if (OriginalData?.needByDateType == "Order Level") {
        return {
          ...col,
          hidden: true,
        };
      } else {
        return col;
      }
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    if (!newSelectedRowKeys) {
      editingKeys.forEach((key) => {
        const row = data.find((item: any) => item.lineNo == key);
        form.setFieldsValue({ [`invoiceQuantity_${row.uuid}`]: null });
        form.setFieldsValue({
          [`unitPrice_${row.uuid}`]: Number(row.unitPrice),
        });
      });
      setEditingKeys(() => []);
      form.resetFields();
    }
    const unSelectedRowKeys = selectedRowKeys.filter(
      (key) => !newSelectedRowKeys.includes(key)
    );
    unSelectedRowKeys?.forEach((key) => {
      const row = data.find((item: any) => item.lineNo == key);
      form.setFieldsValue({ [`invoiceQuantity_${row.uuid}`]: null });
      form.setFieldsValue({ [`unitPrice_${row.uuid}`]: Number(row.unitPrice) });
    });
    const editableRowKeys: any = [];
    newSelectedRowKeys?.forEach((key) => {
      const row = data.find((item: any) => item.lineNo == key);
      if (row) {
        console.log("1111", row);
        form.setFieldsValue({ ...row });
        editableRowKeys.push(row.uuid);
      }
    });
    setEditingKeys(editableRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showDrawer = async () => {
    try {
      const rows = await form.validateFields();
      const invoiceLines: any = [];
      data.forEach((item: any) => {
        if (
          editingKeys.includes(item.uuid) &&
          Number(rows[`invoiceQuantity_` + item.uuid]) > 0
        ) {
          const quantity = rows[`invoiceQuantity_` + item.uuid];
          const unitPrice =
            rows[`unitPrice_` + item.uuid] || Number(item.unitPrice);
          const basicAmount = quantity * unitPrice;
          const sgst = (basicAmount * Number(item.sgstPercentage)) / 100;
          const igst = (basicAmount * Number(item.igstPercentage)) / 100;
          const cgst = (basicAmount * Number(item.cgstPercentage)) / 100;
          invoiceLines.push({
            ...item,
            quantity,
            unitPrice,
            basicAmount,
            sgst,
            igst,
            cgst,
            grossTotal: basicAmount + sgst + igst + cgst,
          });
        }
      });
      setInvoiceableLines(invoiceLines);
      setDrawerVisible(true);
    } catch (error) {
      console.log(error);
    }
  };

  const closeDrawer = () => {
    // form2.resetFields();
    setDrawerVisible(false);
  };

  const fetchCurrentUserInfo = async () => {
    try {
      const res = await usersApis.getCurrentUser();

      if (res) {
        const { prefferedStates } = res;
        setPrefferedStates(prefferedStates?.map((item: any) => item?.stateID));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const methods = useForm({
    mode: "onChange",
  });

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [data, setData] = useState<any>([]);
  const [filterData, setFilterData] = useState<any>([]);
  const invoiceTermsAndConditions = watch("instructions");

  const fetchOrderDetails = async () => {
    try {
      const res = await purchaseOrderApis.fetchOrder(orderNumber);
      const { data, status, message } = res;
      if (status) {
        const {
          currencyCode,
          currencySymbol,
          currencyFormat,
          dms,
          signatureLabel,
          igst,
        } = data;
        setOriginalData(data);
        setPoStatus(data?.orderStatus);
        if (Number(igst) == 0) {
          setTaxConfiguration({
            taxType: "GST",
            gstType: "CGST+SGST",
            cess: [],
          });
        }
        setCurrency({
          description: currencyCode,
          currency_code: currencyCode,
          currency_symbol: currencySymbol,
          currency_format: currencyFormat,
          example: currencyCode,
        });
        if (dms?.length > 0) {
          const _signature = dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: signatureLabel,
              isActive: true,
            });
          }
          const _attachments = dms?.filter(
            (item: any) => item.moduleFileType == "attachments"
          );
          if (_attachments) {
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                name: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachments",
                isActive: true,
              }))
            );
          }
        }
        const _termsAndConditions = dms?.find(
          (item: any) => item.moduleFileType == "termsAndConditions"
        );
        if (_termsAndConditions) {
          const data: any = await fetchHtmlContentFromUrl(
            _termsAndConditions?.filePath
          );
          setTermsAndConditions(data);
        }
        const _instructions = dms?.find(
          (item: any) => item.moduleFileType == "instructions"
        );
        if (_instructions) {
          const data: any = await fetchHtmlContentFromUrl(
            _instructions?.filePath
          );
          setInstructions(data);
        }
        setMaterialData(data);
        const _DeliveryAddressList: any = [];
        const _needByDateList: any = [];
        const LineItems = data?.lineItems?.map((item: any) => {
          if (data?.deliveryAddressType == "Line Level") {
            if (
              !_DeliveryAddressList?.find(
                (address: any) => address?.id == item?.deliveryAddress?.id
              )
            ) {
              _DeliveryAddressList.push({ ...item?.deliveryAddress });
            }
          }
          if (data?.needByDateType == "Line Level") {
            if (
              !_needByDateList?.find((date: any) => date == item?.needByDate)
            ) {
              _needByDateList.push(item?.needByDate);
            }
          }
          const file = dms?.find((doc: any) => doc.lineNo == item.lineNo);
          if (file) {
            item.file = file;
          }
          return item;
        });
        setNeedByDateList(_needByDateList);
        setDeliveryAddressList(_DeliveryAddressList);
        setData(LineItems);
        setFilterData(LineItems);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ViewTableTotals = ({ data, additionalCharges, invoice }: any) => {
    let totalNetAmmount = 0,
      cgst = 0,
      sgst = 0,
      igst = 0,
      TotalAmount = 0;
    data?.forEach((item: any) => {
      const totalAmmount = Number(item.unitPrice) * Number(item.quantity);
      cgst = cgst + (totalAmmount * Number(item.cgstPercentage)) / 100;
      sgst = sgst + (totalAmmount * Number(item.sgstPercentage)) / 100;
      igst = igst + (totalAmmount * Number(item.igstPercentage)) / 100;
      totalNetAmmount = totalNetAmmount + totalAmmount;
    });
    TotalAmount = totalNetAmmount + cgst + sgst + igst;
    additionalCharges?.forEach((item: any) => {
      TotalAmount = TotalAmount + Number(item.price);
    });
    return (
      <Row
        className={`${invoice && "w-50 mt-2"}`}
        style={{
          margin: "20px",
        }}
      >
        <Col className={`justify-end`}>
          <div className={`${invoice ? "w-100" : "w-40"}`}>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>Taxable Amount</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  totalNetAmmount,
                  currency?.currency_code
                )}
              </span>
            </div>
              {(cgst&&sgst)?<>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>CGST</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  cgst,
                  currency?.currency_code
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>SGST</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  sgst,
                  currency?.currency_code
                )}
              </span>
            </div></>:
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>IGST</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  igst,
                  currency?.currency_code
                )}
              </span>
            </div>}
            {additionalCharges?.map((item: any) => {
              if (!item.price || !item.price) return null;
              return (
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>{item.name}</strong>
                  </div>
                  <span className="r_align">
                    {formatCurrency(
                      item.price,
                      currency?.currency_code
                    )}
                  </span>
                </div>
              );
            })}
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "18px", marginTop: "10px" }}
            >
              <div className="w-50 justify-end">
                <strong>Total</strong>
              </div>
              <strong className="r_align">
                {formatCurrency(
                  TotalAmount,
                  currency?.currency_code
                )}
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    fetchCurrentUserInfo();
    fetchOrderDetails();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  const ShowBillingAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      pincode,
      state,
      district,
      countryInfo,
    } = address;
    return (
      <ul>
        <li>
          {deliveryLocationName},{addressLine1}
        </li>
        <li>{addressLine2}</li>
        <li>
          {city}, {district?.districtName}, {state?.stateName},{" "}
          {countryInfo?.countryName} - {pincode}
        </li>
      </ul>
    );
  };
  const ShowDeliveryAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      pincode,
      state,
      district,
      countryInfo,
    } = address;
    return (
      <ul>
        <li>
          {deliveryLocationName},{addressLine1}
        </li>
        <li>{addressLine2}</li>
        <li>
          {city}, {district?.districtName}, {state?.stateName},{" "}
          {countryInfo?.countryName} - {pincode}
        </li>
      </ul>
    );
  };

  const uploadProps = {
    beforeUpload: (file: any) => {
      // Perform any file validation or processing here
      return false; // Return false to prevent auto-upload
    },
    maxCount: 1,
  };

  useEffect(() => {
    let _filterData = data;
    if (selectedNeedByDate) {
      _filterData = _filterData.filter(
        (item: any) => item.needByDate == selectedNeedByDate
      );
    }
    if (selectedDeliveryAddress) {
      _filterData = _filterData.filter(
        (item: any) => item.deliveryAddressId == selectedDeliveryAddress
      );
    }
    setFilterData(_filterData);
  }, [selectedDeliveryAddress, selectedNeedByDate, data]);

  const createInvoice = async (Data: any) => {
    try {
      const _formData = new FormData();
      const invoiceApprovalHierarchy: any = approvals?.data;
      const filesData = [];
      if (invoiceTermsAndConditions) {
        const termsAndConditionsFile = new Blob([invoiceTermsAndConditions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsAndConditions.html", {
            type: "text/html",
          })
        );
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "invoice",
          purpose: "termsAndConditions",
          level: "Header Level",
          uniqueId: nanoid(6),
          action: "Create",
        });
      }
      if (Data?.attachDC && Data?.attachDC[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "invoice",
          purpose: "DCAttachment",
          level: "Header Level",
          uniqueId: nanoid(6),
          action: "Create",
        });
        _formData.append(`files`, Data?.attachDC[0]?.originFileObj);
      }
      if (Data?.attachInvoice && Data?.attachInvoice[0]) {
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "invoice",
          purpose: "InvoiceAttachment",
          level: "Header Level",
          uniqueId: nanoid(6),
          action: "Create",
        });
        _formData.append(`files`, Data?.attachInvoice[0]?.originFileObj);
      }

      const invoiceData: any = {
        orderNumber: Number(orderNumber),
        referenceId: nanoid(6),
        mode: "F",
        invoiceType: Data?.invoiceType,
        invoiceDate: Data?.invoiceDate?.format("YYYY-MM-DD"),
        dueDate: Data?.dueDate?.format("YYYY-MM-DD"),
        deliveryAddressId:
          selectedDeliveryAddress || materialData?.deliveryAddressId,
        autoRelease: Data?.approvals == "false",
        additionalChargesArray: additionalCharges?.map((charge: any) => {
          return {
            name: charge?.name,
            price: charge?.price,
          };
        }),
        lineItems: invoiceableLines?.map((line: any) => {
          return {
            uuid: line?.uuid,
            orderLineNo: line?.lineNo,
            materialId: line?.materialId,
            hsnCode: line?.hsnCode,
            categoryId: line?.categoryId,
            description: line?.description,
            quantityInvoiced: line?.quantity,
            uomId: line?.uomId,
            unitPrice: line?.unitPrice,
            discountPercentage: Number(line?.discountPercentage),
            sgstPercentage: Number(line?.sgstPercentage),
            cgstPercentage: Number(line?.cgstPercentage),
            igstPercentage: Number(line?.igstPercentage),
            additionalCharges: 0,
          };
        }),
      };
      if (Data?.invoiceId) {
        invoiceData.invoiceId = Data?.invoiceId;
      }

      if (invoiceSignature?.image) {
        const image = base64ToFile(invoiceSignature?.image, "signature.png");
        invoiceData.signatureLabel = invoiceSignature?.label;
        _formData.append("files", image);
        filesData.push({
          indexOfFileLocated: filesData.length,
          module: "invoice",
          purpose: "signature",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      }

      _formData.append("invoiceData", JSON.stringify(invoiceData));
      _formData.append("filesData", JSON.stringify(filesData));
      _formData.append(
        "invoiceApprovalHierarchy",
        JSON.stringify(invoiceApprovalHierarchy)
      );
      _formData.append("files", JSON.stringify([]));
      const response = await invoiceApis.submitInvoice(_formData);
      const { status, message, data:_data } = response;
      if (status) {
        Swal.fire({
          icon: "success",
          title: "Invoice Generated",
          text: _data,
        });
        closeDrawer();
        setIsLoading(true);
        navigate("/view-invoice-list");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: message,
          confirmButtonText: "OK",
        });
      }
      setDisableSubmit(false);
    } catch (error) {
      console.log(error);
      setDisableSubmit(false);
    }
  };

  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }

  const returnBillingDetails = () => {
    if (!OriginalData?.billingAddress) {
      return {};
    }
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = OriginalData?.billingAddress;
    const { cin, gstin, pan, phoneNumber } = OriginalData?.orgDetails;
    return {
      name: OriginalData?.orgName || "",
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state?.stateName,
      pincode: pincode,
      country: countryInfo?.countryName,
      gstin: gstin || "",
      pan: pan || "",
      cin: cin || "",
    };
  };

  const returnSupplierDetails = () => {
    if (!OriginalData?.supplierDetails) {
      return {};
    }
    const { cin, gstin, pan, phoneNumber, bankDetails, address } =
      OriginalData?.supplierDetails || {};

    const { bankName, accountNumber, accountHolderName, branch, ifscCode } =
      bankDetails || {};

    const { city, stateInfo, countryInfo, pincode } = address || {};
    return {
      name: OriginalData?.supplierName || "",
      addressLine1: "",
      addressLine2: "",
      city: city || "",
      state: stateInfo?.stateName || "",
      pincode: pincode || "",
      country: countryInfo?.countryName || "",
      gstin: gstin || "",
      pan: pan || "",
      cin: cin || "",
    };
  };
  const returnInvoiceDetails = () => {
    let deliveryAddressString = "";
    if (
      OriginalData?.deliveryAddressType != "Line Level" &&
      OriginalData?.deliveryAddress
    ) {
      const {
        deliveryLocationName,
        addressLine1,
        addressLine2,
        city,
        state,
        countryInfo,
        pincode,
        district,
      } = OriginalData?.deliveryAddress;
      deliveryAddressString = [
        deliveryLocationName,
        addressLine1,
        addressLine2,
        city + ", " + state?.stateName + ", " + district?.districtName,
        countryInfo?.countryName + ", " + pincode,
      ]
        ?.filter(Boolean)
        .join("\n");
    } else {
      deliveryAddressString = "Line Level";
    }
    return {
      invoiceNumber: OriginalData?.invoiceId,
      invoiceDate: "N/A",
      terms:
        materialData?.paymentTerms +
        (materialData?.advancePercentage
          ? `(${materialData?.advancePercentage}%)`
          : ""),
      ewayBillNumber: "",
      placeofSupply: OriginalData?.deliveryAddress?.city || "",
      creditDays:
        materialData?.paymentTerms != "Cash and Carry"
          ? materialData?.creditDays
          : 0,

      deliveryAddressString,
      vehicleRefNo: "N/A",
      transporterName: "N/A",
      orderNumber: OriginalData?.orderId,
      orderDate: OriginalData?.orderDate?dayjs(OriginalData?.orderDate).format("DD-MM-YYYY"): "N/A",
      driverNumber: "N/A",

      totals: {
        taxableAmount: formatDecimal(materialData?.netAmount),
        cgst: formatDecimal(materialData?.cgst),
        sgst: formatDecimal(materialData?.sgst),
        igst: formatDecimal(materialData?.igst),
        total: formatDecimal(materialData?.grossTotal),
      },
      lines: invoiceableLines?.map((item: any, index: any) => {
        let itemDescription = item?.materialName;
        if (item?.description) {
          itemDescription += "\t" + item?.description;
        }
        let deliveryAddressString = "";
        if (
          OriginalData?.deliveryAddressType == "Line Level" &&
          item?.deliveryAddress
        ) {
          const {
            deliveryLocationName,
            addressLine1,
            addressLine2,
            city,
            state,
            countryInfo,
            pincode,
            district,
          } = OriginalData?.deliveryAddress || {};
          deliveryAddressString = [
            deliveryLocationName,
            addressLine1,
            addressLine2,
            city,
            state,
            countryInfo,
            pincode,
            district,
          ]
            ?.filter(Boolean)
            .join(",");
          itemDescription += "\n\nDelivery Address: " + deliveryAddressString;
        }

        return {
          sno: index + 1,
          itemDescription,
          hsnSac: item?.hsnCode || "",
          rate: Number(item?.unitPrice),
          cgstPercentage: Number(item?.cgstPercentage),
          cgstAmount: Number(item?.cgst),
          sgstPercentage: Number(item?.sgstPercentage),
          sgstAmount: Number(item?.sgst),
          igstPercentage: Number(item?.igstPercentage),
          igstAmount: Number(item?.igst),
          taxableAmount: Number(item?.grossTotal),
          qty: Number(item?.quantity),
          uom: item?.uomName,
        };
      }),
    };
  };

  return isLoading == true ? (
    <>
      <Flex align="center" gap="middle" className="pageLoader">
        <Spin size="large" />
      </Flex>
    </>
  ) : (
    <>
      <Head title="Purchase Order - Track" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {!extra?.hideAside&&<div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <InvoiceAside updateSm={setSm} sm={sm} />
            </div>}
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>
                        {OriginalData.orderId} |{" "}
                        {(statusMapper[poStatus] &&
                          statusMapper[poStatus]?.seller?.title) ||
                          poStatus}{" "}
                      </span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <Button
                    className="toggle"
                    color="primary"
                    onClick={async () => {
                      navigate("/add-purchase-invoice");
                    }}
                  >
                    <Icon name="arrow-left" />
                    <span>Back</span>
                  </Button>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <div className="content clearfix">
                <AntCollapse defaultActiveKey={["1", "2", "3"]}>
                  <Panel header="General Information" key="1">
                    <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs w-100 p-0 m-0 position-relative">
                      <li className="nav-item" onClick={() => setTab("1")}>
                        <a
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className={`nav-link${tab === "1" ? " active" : ""}`}
                          href="#year"
                        >
                          General Info
                        </a>
                      </li>
                      <li className="nav-item" onClick={() => setTab("2")}>
                        <a
                          href="#overview"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className={`nav-link${tab === "2" ? " active" : ""}`}
                        >
                          Delivery Info
                        </a>
                      </li>
                      {tab == "2" &&
                        materialData?.needByDateType == "order Level" && (
                          <span
                            className="cursor-pointer position-absolute"
                            style={{
                              right: 20,
                              top: 10,
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (tab === "2") {
                                setValue(
                                  "inwardRequired",
                                  materialData?.inwardRequired
                                );
                                setValue(
                                  "needByDateType",
                                  materialData?.needByDateType
                                );
                                setValue(
                                  "needByDate",
                                  dayjs(materialData?.needByDate)
                                );
                                setValue(
                                  "billingAddress",
                                  materialData?.deliveryAddress
                                    ?.deliveryLocationName
                                );
                                setValue(
                                  "deliveryAddressType",
                                  materialData?.deliveryAddressType
                                );
                                setValue(
                                  "deliveryAddress",
                                  materialData?.deliveryAddress
                                    ?.deliveryLocationName
                                );
                              }
                              setGeneralInformationEdit(true);
                            }}
                          >
                            <Icon name="edit" />
                          </span>
                        )}
                    </ul>

                    <div className="tab-content mt-0">
                      {tab === "1" && (
                        <div className={`tab-pane${tab === "1" && " active"}`}>
                          <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                            <div className="w-50 border-end ">
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-20">Site</div>
                                <div className="data-value w-80 ">
                                  {materialData?.siteName}
                                </div>
                              </div>
                              {/* <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">
                                  Contractor
                                </div>
                                <div className="data-value w-70 ">
                                  {materialData?.contractorName}
                                </div>
                              </div> */}
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-20">Customer</div>
                                <div className="data-value w-80 ">
                                  {materialData?.orgName}
                                </div>
                              </div>
                              <div className="data-col align-center pt-1">
                                <div className="data-label w-20">
                                  Description
                                </div>
                                <div className="data-value w-80">
                                  {materialData?.description || "--"}
                                </div>
                              </div>
                            </div>
                            <div className="w-50 paddingLeft10 ">
                              <div className="data-col align-center pt-1 ">
                                <div className="data-label w-30">
                                  Payment Terms
                                </div>
                                <div className="data-value w-70 ">
                                  <Badge color="outline-success">
                                    {materialData?.paymentTerms || "--"}
                                  </Badge>
                                </div>
                              </div>
                              {materialData?.paymentTerms == "Advance" && (
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">Advance</div>
                                  <div className="data-value w-70 ">
                                    <span className="change up text-danger">
                                      {materialData?.advancePercentage || "--"}%
                                    </span>
                                  </div>
                                </div>
                              )}
                              {materialData?.paymentTerms !=
                                "Cash and Carry" && (
                                <div className="data-col align-center pt-1 ">
                                  <div className="data-label w-30">
                                    Credit Days
                                  </div>
                                  <div className="data-value w-70 ">
                                    <Badge color="outline-primary">
                                      {materialData?.creditDays || "--"} days
                                    </Badge>
                                  </div>
                                </div>
                              )}
                            </div>
                          </Col>
                        </div>
                      )}
                      {tab === "2" && (
                        <div className={`tab-pane${tab === "2" && " active"}`}>
                          <Col className="justify-start w-100  flex-wrap mt-3 p-0">
                            <div className="w-40  paddingLeft10 border-end">
                              <div className=" pt-1">
                                <div className="data-label w-90 mb-1">
                                  Billing Address
                                </div>
                                <div className="data-value w-90">
                                  <ShowBillingAddress
                                    address={materialData?.billingAddress}
                                  />
                                </div>
                              </div>
                            </div>
                            {materialData?.deliveryAddressId && (
                              <div className="w-40 paddingLeft10 ">
                                <div className=" pt-1">
                                  <div className="data-label w-9 mb-1">
                                    Delivery Address
                                  </div>
                                  <div className="data-value w-90">
                                    <ShowDeliveryAddress
                                      address={materialData?.deliveryAddress}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Col>
                        </div>
                      )}
                    </div>
                  </Panel>
                  <Panel header="Material Information" key="2">
                    <Form form={form} component={false}>
                      <div className="d-flex justify-content-between m-0 p-0">
                        <div className="w-70 d-flex align-center">
                          {deliveryAddressList?.length > 0 && (
                            <Form.Item
                              name="invoiceDeliveryAddressId"
                              label=""
                              className="mb-0"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select
                                showSearch
                                allowClear
                                placeholder="Select a delivery address"
                                optionFilterProp="children"
                                onChange={(data: any) => {
                                  setSelectedDeliveryAddress(data);
                                }}
                                onSearch={(data: any) => {}}
                                filterOption={(input, option: any) =>
                                  (option?.label ?? "")
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {deliveryAddressList?.map((item: any) => (
                                  <Option value={item?.id} key={item?.id}>
                                    {item?.deliveryLocationName}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          )}

                          {needByDateList?.length > 0 && (
                            <Form.Item
                              name="invoiceNeedByDate"
                              label=""
                              className="mb-0"
                              style={{ width: 200 }}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select
                                style={{ width: 200, marginLeft: "10px" }}
                                placeholder="Select Need By Date"
                                allowClear
                                optionFilterProp="children"
                                onChange={(selectedOption: any) => {
                                  setSelectedNeedByDate(selectedOption);
                                }}
                                filterOption={(input: string, option: any) =>
                                  option?.label
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={needByDateList?.map((item: any) => ({
                                  value: item,
                                  label: dayjs(item).format("DD-MMM-YYYY"),
                                }))}
                              />
                            </Form.Item>
                          )}
                          {OriginalData?.needByDateType == "Order Level" && (
                            <div className="paddingLeft10">
                              <strong>Need By Date</strong>
                              <p>{OriginalData?.needByDate}</p>
                            </div>
                          )}
                          {OriginalData?.deliveryAddressType ==
                            "Order Level" && (
                            <div
                              className="paddingLeft10"
                              style={{ marginLeft: "10px" }}
                            >
                              <strong>Delivery Address</strong>
                              <p>
                                {
                                  OriginalData?.deliveryAddress
                                    ?.deliveryLocationName
                                }
                              </p>
                            </div>
                          )}
                        </div>
                        <Button
                          color="primary"
                          className="m-0"
                          style={{ height: 40 }}
                          onClick={showDrawer}
                          disabled={selectedRowKeys.length === 0}
                        >
                          Preview Invoice
                        </Button>
                      </div>
                      {filterData?.length > 0 ? (
                        <div className="table-responsive poTable">
                          <div
                            style={{
                            width: screenSize - (extra?.reduceTableWidth||380) + "px",
                              margin: "0px auto",
                            }}
                          >
                            <Table
                              className="customTable"
                              columns={mergedColumns}
                              rowSelection={
                                (OriginalData?.deliveryAddressType ==
                                  "Order Level" ||
                                  selectedDeliveryAddress) &&
                                (OriginalData?.needByDateType ==
                                  "Order Level" ||
                                  selectedNeedByDate)
                                  ? rowSelection
                                  : undefined
                              }
                              components={{
                                body: {
                                  cell: EditableCell,
                                },
                              }}
                              dataSource={filterData.map((item: any) => ({
                                ...item,
                                key: item?.lineNo,
                              }))}
                              scroll={{ x: 1000 }}
                              expandable={{
                                expandedRowRender: (record: any) => (
                                  <div className="expandableBody">
                                    <div style={{ flex: 1, textAlign: "left" }}>
                                      <p style={{ margin: 0 }}>
                                        {record.description}
                                      </p>
                                      {record.file && (
                                        <div>
                                          <PaperClipOutlined /> &nbsp;
                                          <a
                                            href={record?.file?.filePath}
                                            download={record?.file?.filePath}
                                          >
                                            {record?.file?.fileName}
                                          </a>
                                        </div>
                                      )}
                                    </div>
                                    {materialData?.deliveryAddressType ===
                                      "Line Level" &&
                                      record.deliveryAddressId && (
                                        <div
                                          className="lineAddress"
                                          style={{ flex: 1 }}
                                        >
                                          <h6>Delivery Address</h6>
                                          <ShowAddressPopOver
                                            address={record.deliveryAddress}
                                          />
                                        </div>
                                      )}
                                  </div>
                                ),
                              }}
                              bordered
                              size="middle"
                              pagination={false}
                            />
                          </div>
                          {data?.length > 0 && (
                            <ViewTableTotals
                              data={materialData?.lineItems}
                              additionalCharges={[]}
                            />
                          )}
                          <Row className="m-4 justify-content-end ">
                            {signature?.isActive && (
                              <div className="signatureBtnWrapper">
                                <div className="d-flex justify-content-between align-items-center">
                                  <strong>Signature</strong>
                                </div>
                                <div
                                  style={{
                                    minHeight: signature?.image
                                      ? "0px"
                                      : "120px",
                                    display: signature?.image
                                      ? "block"
                                      : "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {signature?.image && (
                                    <>
                                      <div className="signatueImgWrapper border">
                                        <img
                                          src={signature?.image}
                                          alt="Signed"
                                          style={{ width: "160px" }}
                                        />
                                      </div>

                                      <div>
                                        <div className="form-group">
                                          <label
                                            className="form-label"
                                            htmlFor="signatureLabel"
                                          >
                                            Signature Label
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="signatureLabel"
                                            onChange={(e) => {}}
                                            disabled
                                            defaultValue={
                                              signature?.label ||
                                              "Authorised Signatory"
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </Row>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                          }}
                        >
                          <Empty description="No Data Available for this filter" />
                        </div>
                      )}
                    </Form>
                  </Panel>
                  <Panel header="Other Information" key="3">
                    <Descriptions title="" className="custom-description-title">
                      <Descriptions.Item label="Instructions">
                        <span
                          style={{
                            color: "#2263b3",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setInstructionsVisible(true)}
                        >
                          View
                        </span>
                      </Descriptions.Item>
                      <Descriptions.Item label="Terms and Conditions">
                        <span
                          style={{
                            color: "#2263b3",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setTermsVisible(true)}
                        >
                          View
                        </span>
                      </Descriptions.Item>
                      {attachments?.length > 0 && (
                        <Descriptions.Item label="">
                          <div>
                            <strong className="">
                              Attachments &nbsp;
                              <PaperClipOutlined />
                            </strong>
                            <div>
                              {attachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>{index + 1}.</strong>
                                      &nbsp;
                                      {attachment.name}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </Panel>
                </AntCollapse>

                {drawerVisible && (
                  <Drawer
                    title={
                      <div className="d-flex justify-content-between align-items-center">
                        <strong>Create Invoice</strong>
                        <div style={{ textAlign: "right" }}>
                          {disableSubmit ? (
                            <Button color="primary" type="button">
                              <Spinner size="sm" color="light" />
                            </Button>
                          ) : (
                            <>
                              <PdfGenerator
                                orgDetails1={returnSupplierDetails()}
                                orgDetails2={returnBillingDetails()}
                                HeaderDetails={returnInvoiceDetails()}
                                buyerOrgLogo={currentUser?.orgLogo}
                                hasIGST={Number(materialData?.igst) != 0}
                                TermsAndConditions={termsAndConditions}
                              />
                              <Button
                                onClick={() => {
                                  form2.submit();
                                }}
                                color="primary"
                                className="m-3 mt-0 mb-0"
                              >
                                Create
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    }
                    placement="right"
                    onClose={closeDrawer}
                    open={drawerVisible}
                    width="85vw"
                    style={{
                      position: "absolute",
                      zIndex: 1100,
                    }}
                    styles={{ body: { paddingBottom: 80 } }}
                  >
                    <Form
                      form={form2}
                      layout="vertical"
                      autoComplete="off"
                      onFinish={(data) => {
                        setDisableSubmit(true);
                        if (!invoiceSignature?.image) {
                          Swal.fire({
                            icon: "info",
                            title: "Signature",
                            text: "Signature is required",
                          });
                          setDisableSubmit(false);
                          return;
                        }
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You are about to create an invoice for this order!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, Create Invoice!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            createInvoice(data);
                          } else {
                            setDisableSubmit(false);
                          }
                        });
                      }}
                      // requiredMark={false}
                    >
                      <AntCollapse
                        defaultActiveKey={["1", "2"]}
                        className="mt-3"
                      >
                        <Panel
                          header={<h6>Billing & Other Information</h6>}
                          key="1"
                        >
                          <div className="d-flex justify-content-around ">
                            <div className="w-50">
                              <div className="justify-start w-100  flex-wrap pt-0">
                                <div className={` w-50  paddingLeft10`}>
                                  <div className=" pt-1">
                                    <div className="data-label w-90 mb-1">
                                      Billing Address
                                    </div>
                                    <div className="data-value w-90">
                                      <ShowBillingAddress
                                        address={materialData?.billingAddress}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {materialData?.deliveryAddressType !=
                                  "Line Level" &&
                                  materialData?.deliveryAddressId && (
                                    <div className="w-50 paddingLeft10 ">
                                      <div className=" pt-1">
                                        <div className="data-label w-9 mb-1">
                                          Delivery Address
                                        </div>
                                        <div className="data-value w-90">
                                          <ShowDeliveryAddress
                                            address={
                                              materialData?.deliveryAddress
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {materialData?.deliveryAddressType ==
                                  "Line Level" && (
                                  <div className="w-50 paddingLeft10 ">
                                    <div className=" pt-1">
                                      <div className="data-label w-9 mb-1">
                                        Delivery Address
                                      </div>
                                      <div className="data-value w-90">
                                        <ShowDeliveryAddress
                                          address={deliveryAddressList?.find(
                                            (item: any) =>
                                              item?.id ==
                                              selectedDeliveryAddress
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="border-end"></div>
                            <div className="w-45">
                              <div className="justify-start w-100  flex-wrap pt-3">
                                {!sequenceAccess?.includes("Invoice") ? (
                                  <Form.Item
                                    name="invoiceId"
                                    label={
                                      <div className="data-label w-100 mb-0">
                                        Invoice Number
                                      </div>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please Enter Invoice Number",
                                      },
                                    ]}
                                    className="w-50 p-2 paddingLeft10  pt-0 pb-0"
                                  >
                                    <Input
                                      placeholder="Invoice Number"
                                      className="w-100"
                                      onKeyPress={(event) => {
                                        const regex = /^[a-zA-Z0-9\/\-\|]+$/; // Regular expression for alphanumeric characters and special characters ( |, /, - ) only
                                        if (!regex.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                    />
                                  </Form.Item>
                                ) : (
                                  <></>
                                )}
                                <Form.Item
                                  name="invoiceType"
                                  label={
                                    <div className="data-label w-90 mb-0">
                                      Invoice Type
                                    </div>
                                  }
                                  className="w-50 p-3 paddingLeft10  pt-0 pb-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select type",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select Invoice Type">
                                    {/* <Option value="dc">Delivery Challan</Option> */}
                                    <Option value="proforma">
                                      Proforma Invoice
                                    </Option>
                                    <Option value="commercial">
                                      Commercial Invoice
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item
                                  name="approvals"
                                  label={
                                    <div className="data-label w-100 mb-0">
                                      Approval Required ?
                                    </div>
                                  }
                                  className="w-50 p-3 paddingLeft10 pt-0 pb-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select approvals",
                                    },
                                  ]}
                                >
                                  <Select
                                    placeholder="Select Approvals"
                                    value={approvalRequired}
                                    onChange={(e) => {
                                      form2.setFieldValue("approvals", e);
                                      setApprovalRequired(() => e);
                                    }}
                                  >
                                    <Option value="true">Yes</Option>
                                    <Option value="false">No</Option>
                                  </Select>
                                  {approvalRequired == "true" && (
                                    <Popover
                                      content="Click here to set required approvals"
                                      title="Approval Required"
                                    >
                                      <a
                                        href="#"
                                        className="success"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setApprovals((pre) => {
                                            return {
                                              ...pre,
                                              open: true,
                                            };
                                          });
                                        }}
                                      >
                                        <EditOutlined /> Set Approvals &nbsp;
                                        {approvals?.data?.length == 0 ? (
                                          <span className="mandatory">
                                            Please set approvals
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </a>
                                    </Popover>
                                  )}
                                </Form.Item>

                                <Form.Item
                                  name="invoiceDate"
                                  label={
                                    <div className="data-label w-90 mb-0">
                                      Invoice Date
                                    </div>
                                  }
                                  className="w-50 p-3 paddingLeft10  pt-0 pb-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select Invoice Date",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    className="w-100"
                                    placeholder="Invoice Date"
                                    maxDate={dayjs().add(0, "day")}
                                    onChange={(e) => {
                                      setInvoiceDate(e);
                                    }}
                                  />
                                </Form.Item>

                                <Form.Item
                                  name="dueDate"
                                  label={
                                    <div className="data-label w-100 mb-0">
                                      Payment Due Date
                                    </div>
                                  }
                                  className="w-50 p-2 paddingLeft10  pt-0 pb-0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select Payment Due Date",
                                    },
                                  ]}
                                >
                                  <DatePicker
                                    className="w-100"
                                    placeholder="Payment Due Date"
                                    minDate={
                                      invoiceDate
                                        ? dayjs(invoiceDate).add(Number(materialData?.creditDays)||0, "day")
                                        : dayjs().add(0, "day")
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </Panel>

                        <Panel header={<h6>Material Information</h6>} key="2">
                          <div className="table-responsive poTable">
                            <div
                              style={{
                                width: screenSize - 320 + "px",
                                margin: "0px auto 15px auto",
                              }}
                            >
                              <Table
                                className="customTable"
                                columns={mergedColumns.map((col) => {
                                  if (col.dataIndex === "invoiceQuantity") {
                                    return {
                                      ...col,
                                      hidden: true,
                                      // render: (text: any, record: any) =>
                                      //   `${Number(text)} ${record.uom}`,
                                    };
                                  } else if (col.dataIndex === "operation") {
                                    return {
                                      ...col,
                                      hidden: true,
                                    };
                                  } else if (
                                    col.dataIndex === "availableQuantity"
                                  ) {
                                    return {
                                      ...col,
                                      hidden: true,
                                    };
                                  } else if (col.dataIndex == "grossTotal") {
                                    return {
                                      ...col,
                                      fixed: "right",
                                    };
                                  } else if (col.dataIndex == "quantity") {
                                    return {
                                      ...col,
                                      title: "Invoice Quantity",
                                    };
                                  }
                                  return {
                                    ...col,
                                  };
                                })}
                                dataSource={invoiceableLines}
                                scroll={{ x: 1000 }}
                                expandable={{
                                  expandedRowRender: (record: any) => (
                                    <div className="expandableBody">
                                      <div
                                        style={{ flex: 1, textAlign: "left" }}
                                      >
                                        <p style={{ margin: 0 }}>
                                          {record.description}
                                        </p>
                                        {record.file && (
                                          <div>
                                            <PaperClipOutlined /> &nbsp;
                                            <a
                                              href={record?.file?.fileName}
                                              download={record?.file?.fileName}
                                            >
                                              {record?.file?.fileName}
                                            </a>
                                          </div>
                                        )}
                                      </div>
                                      {/* {materialData?.deliveryAddressType ===
                                      "Line Level" &&
                                      record.deliveryAddressId && (
                                        <div
                                          className="lineAddress"
                                          style={{ flex: 1 }}
                                        >
                                          <h6>Delivery Address</h6>
                                          <ShowAddressPopOver
                                            address={record.deliveryAddress}
                                          />
                                        </div>
                                      )} */}
                                    </div>
                                  ),
                                }}
                                bordered
                                size="middle"
                                pagination={false}
                              />
                            </div>
                          </div>

                          <div className="d-flex">
                            <Row className="mt-2 w-50">
                              <div>
                                <div className="d-flex align-items-center">
                                  <h6 className="m-1 mb-0">
                                    Additional Charges (If Any)
                                  </h6>
                                  <div className="w-20 m-1 mb-0">
                                    {editAdditionalCharges ? (
                                      <a
                                        type={undefined}
                                        onClick={() => {
                                          if (
                                            additionalCharges.find(
                                              (field: any, ind: number) => {
                                                const name =
                                                  form2.getFieldValue(
                                                    `AdditionalCharges.${field?.id}.name`
                                                  );
                                                const price =
                                                  form2.getFieldValue(
                                                    `AdditionalCharges.${field?.id}.price`
                                                  );
                                                return !name || !price;
                                              }
                                            )
                                          ) {
                                            return;
                                          }

                                          setAdditionalCharges([
                                            ...additionalCharges,
                                            {
                                              name: "",
                                              price: null,
                                              id: nanoid(4),
                                            },
                                          ]);
                                        }}
                                        className="addNewItem"
                                      >
                                        <PlusSquareOutlined />
                                      </a>
                                    ) : (
                                      <a
                                        type={undefined}
                                        onClick={() => {
                                          setEditAdditionalCharges(true);
                                        }}
                                        className="addNewItem"
                                      >
                                        <EditOutlined />
                                      </a>
                                    )}
                                  </div>
                                </div>
                                {additionalCharges.map(
                                  (field: any, index: number) => (
                                    <Row
                                      key={field.id}
                                      className="m-2 mb-0 w-100"
                                    >
                                      <div className="w-50">
                                        {editAdditionalCharges ? (
                                          <Form.Item
                                            name={`AdditionalCharges.${field.id}.name`}
                                            label={
                                              <strong>
                                                Additional Charge Name
                                              </strong>
                                            }
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Charge Name is required",
                                              },
                                            ]}
                                            className="mb-0"
                                          >
                                            <Input
                                              className="w-100"
                                              disabled={!editAdditionalCharges}
                                              autoComplete="off"
                                            />
                                          </Form.Item>
                                        ) : (
                                          <strong>{field.name}</strong>
                                        )}
                                      </div>
                                      <div className="w-30">
                                        {editAdditionalCharges ? (
                                          <Form.Item
                                            name={`AdditionalCharges.${field.id}.price`}
                                            label={<strong>Price (INR)</strong>}
                                            rules={[
                                              {
                                                required: true,
                                                message: "Price is required",
                                              },
                                            ]}
                                            className="mb-0"
                                          >
                                            <InputNumber
                                              min={0}
                                              disabled={!editAdditionalCharges}
                                              className="w-100"
                                              formatter={(value) =>
                                                `${value}`.replace(
                                                  /\B(?=(\d{3})+(?!\d))/g,
                                                  ","
                                                )
                                              }
                                              parser={(value: any) =>
                                                value!.replace(
                                                  /\$\s?|(,*)/g,
                                                  ""
                                                )
                                              }
                                              onKeyPress={(event) => {
                                                if (
                                                  !/^[0-9]+$/.test(event.key)
                                                ) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              autoComplete="off"
                                            />
                                          </Form.Item>
                                        ) : (
                                          <strong>
                                            {formatCurrency(
                                              field.price,
                                              currency?.currency_code
                                            )}
                                          </strong>
                                        )}
                                      </div>
                                      <Col className="d-flex align-items-end w-15">
                                        {editAdditionalCharges && (
                                          <a
                                            href="#dropdownitem"
                                            onClick={() => {
                                              form2.setFieldsValue({
                                                [`AdditionalCharges.${field.id}.name`]:
                                                  "",
                                                [`AdditionalCharges.${field.id}.price`]:
                                                  null,
                                              });
                                              const newAdditionalCharges: any =
                                                [];
                                              additionalCharges.forEach(
                                                (fld: any, ind: number) => {
                                                  if (fld.id != field.id) {
                                                    const name =
                                                      form2.getFieldValue(
                                                        `AdditionalCharges.${fld.id}.name`
                                                      );
                                                    const price =
                                                      form2.getFieldValue(
                                                        `AdditionalCharges.${fld.id}.price`
                                                      );
                                                    if (name && price) {
                                                      newAdditionalCharges.push(
                                                        {
                                                          name,
                                                          price,
                                                          id: fld.id,
                                                        }
                                                      );
                                                    }
                                                  }
                                                }
                                              );
                                              setAdditionalCharges(
                                                newAdditionalCharges
                                              );
                                            }}
                                            className="addNewItem"
                                          >
                                            <DeleteOutlined />
                                          </a>
                                        )}
                                      </Col>
                                    </Row>
                                  )
                                )}
                                {additionalCharges.length > 0 &&
                                  editAdditionalCharges && (
                                    <Button
                                      className="m-4 mt-3 mb-0"
                                      color="primary"
                                      onClick={() => {
                                        const newAdditionalCharges: any = [];
                                        additionalCharges.forEach(
                                          (field: any, index: number) => {
                                            const name = form2.getFieldValue(
                                              `AdditionalCharges.${field?.id}.name`
                                            );
                                            const price = form2.getFieldValue(
                                              `AdditionalCharges.${field?.id}.price`
                                            );
                                            if (name && price) {
                                              newAdditionalCharges.push({
                                                name,
                                                price,
                                                id: field?.id,
                                              });
                                            }
                                          }
                                        );
                                        setAdditionalCharges(() => {
                                          return newAdditionalCharges;
                                        });
                                        if (newAdditionalCharges.length != 0) {
                                          setEditAdditionalCharges(false);
                                        }
                                      }}
                                    >
                                      Save
                                    </Button>
                                  )}
                              </div>
                            </Row>

                            <ViewTableTotals
                              data={invoiceableLines}
                              additionalCharges={additionalCharges}
                              invoice={true}
                            />
                          </div>
                          <Row className="m-4 justify-content-end ">
                            {invoiceSignature?.isActive ? (
                              <div className="signatureBtnWrapper">
                                <div className="d-flex justify-content-between align-items-center">
                                  <strong>Signature</strong>
                                  <a
                                    style={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      setInvoiceSignature((prev: any) => ({
                                        ...prev,
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      }));
                                      setValue("signatureObj", {
                                        isActive: false,
                                        image: null,
                                        label: "Authorised Signatory",
                                      });
                                    }}
                                  >
                                    <Icon
                                      name="cross"
                                      className="pointer"
                                      onClick={() => {
                                        setInvoiceSignature((prev: any) => ({
                                          ...prev,
                                          isActive: false,
                                          image: null,
                                          label: "Authorised Signatory",
                                        }));
                                        setValue("signatureObj", {
                                          isActive: false,
                                          image: null,
                                          label: "Authorised Signatory",
                                        });
                                      }}
                                    />
                                  </a>
                                </div>
                                <div
                                  style={{
                                    minHeight: invoiceSignature?.image
                                      ? "0px"
                                      : "120px",
                                    display: invoiceSignature?.image
                                      ? "block"
                                      : "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {!invoiceSignature?.image && (
                                    <Button
                                      color="btn-dim btn"
                                      onClick={() => {
                                        setShowCropModel(true);
                                      }}
                                    >
                                      <Icon
                                        name="upload-cloud"
                                        className="pointer"
                                        onClick={() => {
                                          setShowCropModel(true);
                                        }}
                                      />
                                      <span>Upload</span>
                                    </Button>
                                  )}
                                  {invoiceSignature?.image && (
                                    <>
                                      <div className="signatueImgWrapper border">
                                        <img
                                          src={invoiceSignature?.image}
                                          alt="Signed"
                                          style={{ width: "160px" }}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <Button
                                          color="btn-dim btn"
                                          onClick={() => {
                                            setInvoiceSignature((pre: any) => {
                                              return { ...pre, image: null };
                                            });
                                          }}
                                        >
                                          <span>Reset</span>
                                          <Icon
                                            name="redo"
                                            className="pointer"
                                            onClick={() => {
                                              setInvoiceSignature(
                                                (pre: any) => {
                                                  return {
                                                    ...pre,
                                                    image: null,
                                                  };
                                                }
                                              );
                                            }}
                                          />
                                        </Button>
                                      </div>
                                      <div>
                                        <div className="form-group">
                                          <label
                                            className="form-label"
                                            htmlFor="signatureLabel"
                                          >
                                            Signature Label
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="signatureLabel"
                                            onChange={(e) => {
                                              setInvoiceSignature(
                                                (pre: any) => {
                                                  return {
                                                    ...pre,
                                                    label: e.target.value,
                                                  };
                                                }
                                              );
                                            }}
                                            defaultValue={
                                              invoiceSignature?.label ||
                                              "Authorised Signatory"
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>

                                {!invoiceSignature?.image && (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <Button
                                        color="btn-dim btn"
                                        onClick={() =>
                                          setShowSignatureModel(true)
                                        }
                                      >
                                        <Icon name="pen" />
                                        <span>Use Signature Pad</span>
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <div
                                style={{
                                  border: "1px solid #e5ecf7",
                                  backgroundColor: "#f7faff",
                                  padding: "10px",
                                  marginLeft: "10px",
                                  width: "22%",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                }}
                              >
                                <Button
                                  color="btn-dim btn"
                                  onClick={() => {
                                    setInvoiceSignature((pre: any) => {
                                      return { ...pre, isActive: true };
                                    });
                                  }}
                                >
                                  <Icon
                                    name="edit"
                                    className="pointer"
                                    onClick={() => {
                                      setInvoiceSignature((pre: any) => {
                                        return { ...pre, isActive: true };
                                      });
                                    }}
                                  />
                                  <span>Add Signature</span>
                                </Button>
                              </div>
                            )}
                          </Row>
                        </Panel>
                        <Panel header={<h6>Terms and Conditions</h6>} key="3">
                          <FormProvider {...methods}>
                            <form onSubmit={() => {}}>
                              <FormTextArea
                                name="instructions"
                                label={undefined}
                                placeholder="Enter Instructions"
                              />
                            </form>
                          </FormProvider>
                        </Panel>
                        <Panel header={<h6>Attachments (If Any)</h6>} key="4">
                          <div className="w-100 d-flex m-2">
                            <div className="w-25">
                              <Form.Item
                                name="attachDC"
                                label={<strong>Attach DC</strong>}
                                valuePropName="fileList"
                                getValueFromEvent={(e: any) =>
                                  Array.isArray(e) ? e : e?.fileList
                                }
                              >
                                <Upload {...uploadProps} className="w-100">
                                  <AntButton icon={<UploadOutlined />}>
                                    Click to Upload
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </div>
                            <div className="w-25">
                              <Form.Item
                                name="attachInvoice"
                                label={<strong>Attach Invoice</strong>}
                                valuePropName="fileList"
                                getValueFromEvent={(e: any) =>
                                  Array.isArray(e) ? e : e?.fileList
                                }
                              >
                                <Upload {...uploadProps} className="w-100">
                                  <AntButton icon={<UploadOutlined />}>
                                    Click to Upload
                                  </AntButton>
                                </Upload>
                              </Form.Item>
                            </div>
                          </div>
                        </Panel>
                      </AntCollapse>
                      {showCropModel && (
                        <CropperComponent
                          modal={showCropModel}
                          toggle={() => setShowCropModel(!showCropModel)}
                          onSave={(data: string) => {
                            setInvoiceSignature((pre: any) => {
                              return { ...pre, image: data };
                            });
                            setShowCropModel(false);
                          }}
                        />
                      )}
                      {showSignatureModel && (
                        <SignatureModal
                          modal={showSignatureModel}
                          toggle={() =>
                            setShowSignatureModel(!showSignatureModel)
                          }
                          onSave={(data: string) => {
                            setInvoiceSignature((pre: any) => {
                              return { ...pre, image: data };
                            });
                            setShowSignatureModel(false);
                          }}
                        />
                      )}
                    </Form>
                  </Drawer>
                )}

                <AntModal
                  title="Instructions"
                  open={instructionsVisible}
                  footer={null}
                  onCancel={() => setInstructionsVisible(false)}
                  width="60%"
                  styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
                >
                  {instructions ? (
                    <pre>{instructions}</pre>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty description="No Instructions Provided" />
                    </div>
                  )}
                </AntModal>
                <AntModal
                  title="Terms and Conditions"
                  open={termsVisible}
                  footer={null}
                  onCancel={() => setTermsVisible(false)}
                  width="60%"
                  styles={{ body: { maxHeight: "70vh", overflowY: "auto" } }}
                >
                  {termsAndConditions ? (
                    <pre>{termsAndConditions}</pre>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "200px",
                      }}
                    >
                      <Empty description="No Terms and Conditions Provided" />
                    </div>
                  )}
                </AntModal>
              </div>
            </div>
          </div>
        </Card>
      </Content>

      {approvals?.open && (
        <QuotationApprovalDrawer
          open={approvals.open}
          onClose={() => {
            setApprovals((pre) => {
              return {
                ...pre,
                open: false,
              };
            });
          }}
          onSubmit={(data: any) => {
            setApprovals({
              open: false,
              data: data,
            });
          }}
          stateIds={prefferedStates}
          setApprovalSubmissionStatus={(status: boolean) => {}}
          approvalData={approvals.data} // Pass the current approval data
        />
      )}

      <Modal
        isOpen={generalInformationEdit}
        toggle={() => setGeneralInformationEdit(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setGeneralInformationEdit(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>
          {tab == "2" && (
            <>
              <h5 className="title"> Update Delivery Info</h5>
              <div style={{ marginTop: "15px" }}></div>
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit((data) => {
                    setMaterialData((pre: any) => {
                      return {
                        ...pre,
                        ...data,
                      };
                    });
                    setGeneralInformationEdit(false);
                    console.log(data);
                  })}
                >
                  <Row className="gy-4 mt-1">
                    <Col lg="4">
                      <FormSelect
                        name="inwardRequired"
                        label="Inward Required ?"
                        options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ]}
                        disabled={true}
                        placeholder="-Select-"
                        tooltipText=" If GRN (Inward Process) is required for the line items then you can select 'Yes' otherwise select 'No'."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="needByDateType"
                        disabled
                        label="Line Level Delivery ?"
                        options={[
                          { label: "Yes", value: "Line Level" },
                          { label: "No", value: "Order Level" },
                        ]}
                        placeholder="-Select-"
                        tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
                      />
                    </Col>
                    {watch("needByDateType") == "Order Level" && (
                      <Col lg="4">
                        <FormDatePicker
                          name="needByDate"
                          label="Delivery Date"
                          required={true}
                          minDate={new Date()}
                        />
                      </Col>
                    )}
                    <Col lg="4">
                      <FormInput
                        name="billingAddress"
                        label={`Billing Address`}
                        disabled
                        placeholder="-Select-"
                        tooltipText="Select the billing address for the purchase order."
                      />
                    </Col>
                    <Col lg="4">
                      <FormSelect
                        name="deliveryAddressType"
                        label={`Delivery Address`}
                        disabled
                        options={[
                          { label: "Order Level", value: "Order Level" },
                          { label: "Line Level", value: "Line Level" },
                        ]}
                        placeholder="-Select-"
                        tooltipText="Select the delivery address type for the purchase order."
                      />
                    </Col>
                    {watch("deliveryAddressType") === "Order Level" && (
                      <Col lg="4">
                        <FormInput
                          name="deliveryAddress"
                          label="Delivery Address"
                          placeholder="-Select-"
                          tooltipText="Select the delivery address for the purchase order."
                          disabled
                        />
                      </Col>
                    )}
                  </Row>
                  <Row className="mt-4">
                    <Col lg="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button
                            className="btn btn-primary btn-md"
                            type="submit"
                            color="primary"
                          >
                            Save
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#cancel"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setGeneralInformationEdit(false);
                              reset();
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </form>
              </FormProvider>
            </>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

const fetchHtmlContentFromUrl = async (url: string) => {
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "text/html",
      },
    });

    return response?.data;
  } catch (error) {
    console.log(error);
    return null;
  }
};
const ShowAddressPopOver = ({ address }: any) => {
  return (
    <ul>
      <li>{address.addressLine1}</li>
      <li>{address.addressLine2}</li>
      <li>
        {address.city}, {address.stateName}, {address.countryName} -{" "}
        {address.pincode}
      </li>
    </ul>
  );
};

export default InvoiceDetails;